/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {

	// Registering GSAP plugin

	gsap.registerPlugin(ScrollTrigger);

	// Global Variables

	var pageY = 0,
		factor = 0,
		headerHeight = $('#masthead').outerHeight(true) + $('.site-logo').outerHeight(),
		menuButton = $('.menu-button'),
		mask = $('.header-image #cover'),
		maskHeight = mask.outerHeight(),
		claim = $('.header-image #cover text'),
		progress = $('.progress-bar'),
		indicator = progress.find('.indicator');

	// All-click objects

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').on('click', function(e) {
				e.preventDefault();
			});
			
			if ($this.find('a').attr('target') == '_blank') {
				$this.on('click', function(e) {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.on('click', function(e) {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	// Smooth scrolling

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			if (this.hash !== "") {
				e.preventDefault();

				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - headerHeight
				}, 1000);
			}
		});
	}

	// Code to run when the document is ready

	$( document ).ready(function() {

		allClick();
		smoothScroll();

		// Intro Animations

		$('#page').addClass( 'init' );

		setTimeout(function() {
			claim.removeClass( 'hidden' );
		}, 2500);

		// Remove videos autoplay when the user has requested
		// the operating system to minimize the amount of motion it uses

		var video = $('video');
		if ( window.matchMedia('(prefers-reduced-motion)').matches ) {
			video.removeAttribute('autoplay');
			video.pause();
		}

		// Inhibit click on primary menu items

		$('#primary-menu').find('a[href="#"]').each(function() {
			$(this).on('click', function(e) {
				e.preventDefault();

				$('#primary-menu .menu-item > a').not(this).next('.sub-menu').removeClass('open');

				if ( $(this).next('.sub-menu').hasClass('open') ) {
					$(this).next('.sub-menu').removeClass('open');
				} else {
					$(this).next('.sub-menu').addClass('open');
				}
			});
		});

		// Items filtering

		$('.item-filters .filter').on('click', function(event) {
			event.preventDefault();
			$('.item-filters .filter').removeClass('active');
			$(this).addClass('active');

			var mix = $(this).data('filter');
			var items = $('[data-mix]');

			if ( mix != "all" ) {
				items.hide();
				$('[data-mix*="' + mix + '"]').fadeIn(500);
			} else {
				items.fadeIn(500);
			}
		});

		// Adjust padding for page without header images
		
		if ( $('body').hasClass('no-header-images') && ! $('.featured').length ) {
			$('#page').css( 'padding-top', headerHeight );
		}

	});

	// Code to run when the entire page is ready

	$( window ).on( 'load', function() {

		/* GSAP Animations - Begin */

		// Menu
		var menu = gsap.timeline({
			paused: true
		});

		menu.to(
			'#menu-offcanvas',
			{
				duration: 1,
				scaleX: 1,
				ease: 'power2.inOut',
				stagger: 0.2
			}
		).from(
			'.menu-image',
			{
				duration: 0.75,
				opacity: 0,
				ease: 'power2.inOut',
				stagger: 0.2
			}
		).to(
			'.menu-item',
			{
				duration: 0.5,
				opacity: 1,
				ease: 'power2.inOut',
				stagger: 0.1
			},
			'-=0.3'
		);

		// Mask (fading)
		if ( mask.length > 0 ) {
			gsap.to(
				mask,
				{
					duration: 1,
					opacity: 0,
					ease: 'power2.inOut',
					scrollTrigger: {
						trigger: mask,
						start: 'top+=20 top',
						toggleActions: 'play none none reverse'
					}
				}
			);
		}

		// Titles (sliding up)
		var titles = gsap.utils.toArray('.slide');

		titles.forEach((title) => {
			var delay = title.getAttribute('data-delay'),
				scroll = title.getAttribute('data-scroll'),
				start = 'top center';

			if ( scroll == 'no' ) start = 'top bottom';

			title.parentNode.style.overflow = 'hidden';

			gsap.from(
				title,
				{
					delay: delay,
					duration: 1.5,
					yPercent: 120,
					scrollTrigger: {
						trigger: title,
						start: start
					}
				}
			);
		});

		// Texts (fading up)
		var texts = gsap.utils.toArray('.fade');

		texts.forEach((text) => {
			var delay = text.getAttribute('data-delay'),
				scroll = text.getAttribute('data-scroll'),
				start = 'top center';

			if ( scroll == 'no' ) start = 'top bottom';

			gsap.from(
				text,
				{
					delay: delay,
					duration: 1,
					autoAlpha: 0,
					y: 50,
					scrollTrigger: {
						trigger: text,
						start: start
					}
				}
			);
		});

		// Service icons (zoom)
		var icons = gsap.utils.toArray('#services img, .room-services img');

		icons.forEach((icon) => {
			gsap.to(
				icon,
				{
					duration: 1,
					scale: 1,
					scrollTrigger: {
						trigger: icon,
						start: 'top bottom',
						end: 'bottom top'
					}
				}
			);
		});

		// Single letter animation
		$('.letter-animation').each(function(index) {
			var triggerElement = $(this);
			var targetElement = $(this).find('span');
		
			var tl = gsap.timeline({
				scrollTrigger: {
					trigger: triggerElement,
					start: 'top bottom',
					end: 'bottom top'
				}
			});

			tl.from(targetElement, {
				duration: 1.5,
				y: '60%',
				opacity: 0,
				rotationX: -90,
				stagger: {
					amount: 0.7,
					from: '0'
				}
			});
		});

		// Animation effects
		if ( $('#animation').length > 0 ) {
			var text = '#animation .text',
				door = '#animation .door-window',
				sun = '#animation .sun';

			gsap.from(
				text,
				{
					duration: 1,
					yPercent: 50,
					scrollTrigger: {
						trigger: text,
						start: 'top bottom',
						end: 'bottom top',
						toggleActions: 'play none none reverse'
					}
				}
			);

			gsap.from(
				sun,
				{
					duration: 1,
					autoAlpha: 0,
					rotation: -180,
					yPercent: 50,
					scrollTrigger: {
						trigger: sun,
						start: 'top bottom',
						end: 'bottom top',
						toggleActions: 'play none none reverse'
					}
				}
			);

			gsap.from(
				door,
				{
					duration: 1,
					yPercent: 50,
					scrollTrigger: {
						trigger: door,
						start: 'top bottom',
						end: 'bottom top',
						toggleActions: 'play none none reverse'
					}
				}
			);
		}

		/* GSAP Animations - End */

		// Hide/show menu off canvas

		menuButton.on('click', function(e) {
			e.preventDefault();
			
			$(this).toggleClass( 'collapsed' );

			if ( ! $(this).hasClass('collapsed') ) {
				$('#primary-menu .menu-item > a').next('.sub-menu').removeClass('open');
				menu.play();
			} else {
				menu.reverse();
			}

			$('body').toggleClass( 'open-menu' );
		});

		// Equal Heights
		if ( $(window).width() >= 1024 ) {
			$('.equalHeight').equalHeights();
		}

	});

	// Code to run when the browser window has been resized

	$( window ).on( 'resize', function() {

		

	});

	// Code to run when the browser window scrolls

	$( window ).on( 'scroll', function() {

		if ( $(window).width() >= 1024 ) {
			pageY = window.pageYOffset;

			// Change color over mask height
			if ( maskHeight ) {
				if ( pageY > maskHeight - progress.height() ) {
					menuButton.removeClass( 'pale' );
					progress.removeClass( 'pale' );
				} else {
					menuButton.addClass( 'pale' );
					progress.addClass( 'pale' );
				}
			}

			// Progress bar
			factor = ( 1 / ( $(document).height() - $(window).height() ) ) * pageY;
			indicator.css( 'transform', 'scale(1,' + factor + ')' );
		}

	});

})(jQuery);

// Magic mouse

if ( document.documentElement.clientWidth > 1024 ) {
	var options = {
		"cursorOuter": "circle-basic",
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 20,
		"outerHeight": 20
	};

	magicMouse(options);
}

/* Swiper - Begin */

// Reasons slideshow
const reasonsImages = new Swiper('#reasons .images-slideshow', {
	slidesPerView: 1.125,
	spaceBetween: 15,
	speed: 1000,
	navigation: {
		nextEl: '#reasons .swiper-button-next',
		prevEl: '#reasons .swiper-button-prev',
	},
	breakpoints: {
		640: {
			slidesPerView: 1.25,
			spaceBetween: 0
		}
	},
});

const reasonsContents = new Swiper('#reasons .contents-slideshow', {
	speed: 1000,
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
	allowTouchMove: false,
});

reasonsContents.controller.control = reasonsImages;
reasonsImages.controller.control = reasonsContents;

// Rooms slideshow
const roomsContents = new Swiper('#rooms .contents-slideshow', {
	speed: 1000,
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
	allowTouchMove: false,
});

const roomsImages = new Swiper('#rooms .images-slideshow', {
	slidesPerView: 1,
	speed: 1000,
	navigation: {
		nextEl: '#rooms .swiper-button-next',
		prevEl: '#rooms .swiper-button-prev',
	},
});

roomsContents.controller.control = roomsImages;
roomsImages.controller.control = roomsContents;

// Offers slideshow
const offersImages = new Swiper('#offers .images-slideshow', {
	slidesPerView: 1.125,
	spaceBetween: 15,
	speed: 1000,
	navigation: {
		nextEl: '#offers .swiper-button-next',
		prevEl: '#offers .swiper-button-prev',
	},
	breakpoints: {
		640: {
			slidesPerView: 1.25,
			spaceBetween: 0
		}
	},
});

const offersContents = new Swiper('#offers .contents-slideshow', {
	speed: 1000,
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
	allowTouchMove: false,
});

offersContents.controller.control = offersImages;
offersImages.controller.control = offersContents;

// Reviews slideshow
const reviewsSlider = new Swiper('#reviews .reviews-slideshow', {
	speed: 1000,
	navigation: {
		nextEl: '#reviews .swiper-button-next',
		prevEl: '#reviews .swiper-button-prev',
	},
	breakpoints: {
		1024: {
			slidesPerView: 2,
			spaceBetween: 60,
		}
	},
});

// Social slideshow
const socialSlider = new Swiper('#social .social-slideshow', {
	slidesPerView: 1.125,
	spaceBetween: 15,
	speed: 1000,
	navigation: {
		nextEl: '#social .swiper-button-next',
		prevEl: '#social .swiper-button-prev',
	},
	breakpoints: {
		640: {
			slidesPerView: 1.25,
			spaceBetween: 20
		},
		992: {
			slidesPerView: 2.25,
			spaceBetween: 40
		}
	},
});

/* Swiper - End */
